import * as React from "react";
import { graphql } from "gatsby";

import Breadcrumbs from "@components/Breadcrumbs";
import Button from "@components/Button";
import Seo from "@components/Seo";

import Step from "@page_components/reservation/Step";
import ReservationForm from "@page_components/reservation/ReservationForm";

import BooksyLogo from "@icons/booksy.svg";

function ReservationPage({ data }) {
  const { seo } = data.allWpPage.nodes[0];
  const breadcrumbs_data = [{ name: "Rezerwacja wizyty" }];
  const { agreement, bottomText, howFast, steps } =
    data.allWpPage.nodes[0].pageReservation;

  const offers = data.offers.nodes;

  return (
    <>
      <Seo title={seo.title} description={seo.metaDesc} />
      <div className="reservation-page">
        <div className="container">
          <Breadcrumbs data={breadcrumbs_data} />

          <main className="reservation-page__main">
            <h1> Rezerwacja wizyty </h1>
            <div className="reservation-page__instruction">
              {steps.map((step) => (
                <Step
                  number={step.number}
                  title={step.title}
                  subtitle={step.description}
                  key={step.number}
                />
              ))}
            </div>
            <div className="reservation-page__form">
              <ReservationForm
                agreement={agreement}
                offerOptions={offers}
                howFastOption={howFast}
              />
            </div>
          </main>

          <aside className="reservation-page__aside">
            <h5>{bottomText}</h5>
            <Button href="/kontakt">Kontakt</Button>
            <a
              href="http://booksy.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <BooksyLogo
                width={198}
                height={45}
                className="reservation-page__booksy"
              />
            </a>
          </aside>
        </div>
      </div>
    </>
  );
}

export default ReservationPage;

export const query = graphql`
  {
    allWpPage(filter: { title: { eq: "Rezerwacja wizyty" } }) {
      nodes {
        pageReservation {
          agreement
          bottomText
          howFast {
            text
          }
          steps {
            number
            title
            description
          }
        }
        seo {
          title
          metaDesc
        }
      }
    }
    offers: allWpOferta {
      nodes {
        title
      }
    }
  }
`;
